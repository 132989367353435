import React from "react";
import { useLocaleContext } from "../context/localeContext";
import { GenericUtil } from "../utils";

const LocaleSelect = () => {
  const locales = ["de", "en"];
  const { locale, setLocale } = useLocaleContext();
  console.log(locale);
  const [value, setValue] = React.useState(
    locales.filter((item) => item === locale)[0]
  );
  const handleChange = (e) => {
    if (e) {
      console.log(e);
      setValue(e.target.value);
      GenericUtil.setLanguage(e.target.value);
      setLocale(e.target.value);
    }
  };
  return (
    <div style={{ color: "black" }}>
      <p className="d-inline pr-2 label">Choose preferred language:</p>
      <label className="radio-inline">
        <input
          type="radio"
          value="de"
          checked={value === "de"}
          onChange={handleChange}
        />
        <p className="d-inline pl-2 pr-2 label">German</p>
      </label>
      <label className="radio-inline">
        <input
          type="radio"
          value="en"
          checked={value === "en"}
          onChange={handleChange}
        />
        <p className="d-inline pl-2 pr-2">English</p>
      </label>
    </div>
  );
};

export default LocaleSelect;
