import React, { useContext } from "react";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";
import Hooks from "../hooks";
import CategoryDropDown from "./category-drop-down";
import CategoryDropDownMobile from "./category-drop-down-mobile";
import LocaleSelect from "./LocaleSelect";
import { CartContext } from "../context/cartContext";
import _get from "lodash.get";
import { Link } from "react-router-dom";

const Header = () => {
  const { locale } = useLocaleContext();
  const { quickLinks, logoUrl, categoryData } = Hooks.useHeader(locale);
  const [globalCart] = useContext(CartContext);
  return (
    <div className="header" id="mainHeader">
      <div className="container-fluid">
        <div className="header-wrapper">
          <a href="/" rel="noreferrer" target="_self" className="header-logo">
            <img height="60px" width="120px" alt="logo" src={logoUrl} />
          </a>
          <ul className="header-nav">
            <CategoryDropDown categoryData={categoryData}>
              <a>{TT(LabelKeys.HEADER_PRODUCTS, locale)}</a>
            </CategoryDropDown>
            {quickLinks &&
              quickLinks.map((navLink) => (
                <li>
                  <a href={navLink.title} key={navLink.title}>
                    {navLink.title}
                  </a>
                </li>
              ))}
          </ul>
          <div className="header-icon-nav">
            <button
              className="btn btn-light collapsed"
              data-toggle="collapse"
              data-target="#search-box"
              aria-controls="search-box"
            >
              <i className="far fa-search see"></i>
              <i className="fal fa-times closed"></i>
            </button>
            <Link
              to={`/review/${_get(globalCart, "id")}`}
              className={`btn btn-light collapsed ${
                _get(globalCart, "id") ? "" : "link-disabled"
              }`}
            >
              <i className="fal fa-shopping-bag"></i>
              <span class="badge">{_get(globalCart, "lineItems.length")}</span>
            </Link>
            <button
              className="btn btn-light collapsed"
              data-toggle="collapse"
              data-target="#login-box"
              aria-controls="login-box"
            >
              <i className="fal fa-user see"></i>
              <i className="fal fa-times closed"></i>
            </button>
            <button
              className="btn btn-light collapsed btn-toggle mobile-toggled"
              data-toggle="collapse"
              data-target="#menu-box"
              aria-controls="menu-box"
            >
              <i className="fal fa-bars see"></i>
              <i className="fal fa-times closed"></i>
            </button>
          </div>
        </div>
      </div>
      <div
        className="header-box collapse fade"
        id="search-box"
        data-parent="#mainHeader"
      >
        <div className="header-box-body">
          <div className="container">
            <form action="">
              <div className="form-group">
                <label htmlFor="header-search" className="form-label">
                  {TT(LabelKeys.HEADER_SEARCH, locale)}
                </label>
                <input
                  type="text"
                  id="header-search"
                  className="form-control max-width"
                  placeholder="Search..."
                />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div
        className="header-box collapse fade"
        id="login-box"
        data-parent="#mainHeader"
      >
        <div className="header-box-body">
          <div className="container">
            <div className="row">
              <div className="col-md-6">
                <LocaleSelect />
              </div>
              {/*  <div className="col-12 col-md-9">
                <form action="">
                  <div className="form-group">
                    <label htmlFor="username" className="form-label">
                      {TT(LabelKeys.HEADER_LOGIN, locale)}
                    </label>
                    <input
                      type="text"
                      id="username"
                      className="form-control"
                      placeholder="Email Address"
                    />
                  </div>
                  <div className="form-group d-flex flex-column flex-md-row">
                    <input
                      type="password"
                      id="password"
                      className="form-control mr-2"
                      placeholder="Password"
                    />
                    <button className="btn btn-login">
                      <div className="btn-text">
                        <span> {TT(LabelKeys.HEADER_LOGIN, locale)}</span>
                      </div>
                      <i className="fal fa-arrow-circle-right"></i>
                    </button>
                  </div>
                </form>
              </div>
              <div className="col-12 col-md-3">
                <div className="register-nav">
                  <a href="#" className="btn btn-light">
                    <div className="btn-text">
                      <span> {TT(LabelKeys.HEADER_REGISTER, locale)}</span>
                      <small>
                        {TT(LabelKeys.HEADER_REGISTER_SUB_TEXT, locale)}
                      </small>
                    </div>
                    <i className="fal fa-arrow-circle-right"></i>
                  </a>
                  <a href="#" className="btn btn-light">
                    <div className="btn-text">
                      <span>
                        {TT(LabelKeys.HEADER_FORGOT_PASSWORD, locale)}
                      </span>
                    </div>
                    <i className="fal fa-arrow-circle-right ml-1"></i>
                  </a>
                </div>
              </div>*/}
            </div>
            <div className="account-nav d-none">
              <div className="account-nav-header">
                <span className="account-nav-header--title">
                  {TT(LabelKeys.HEADER_ACCOUNT, locale)}
                </span>
                <small className="account-nav-header--detail">
                  {TT(LabelKeys.HEADER_CUSTOMER_ID, locale)}
                  <b>0123456789</b>
                </small>
              </div>
              <div className="account-nav-body">
                <li>
                  <a href="#">
                    <span> {TT(LabelKeys.HEADER_PROFILE, locale)}</span>
                    <i className="fal fa-arrow-right"></i>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span> {TT(LabelKeys.HEADER_ORDER_REVIEW, locale)}</span>
                    <i className="fal fa-arrow-right"></i>
                    <span class="badge badge-light">9</span>
                  </a>
                </li>
                <li>
                  <a href="#">
                    <span> {TT(LabelKeys.HEADER_LOGOUT, locale)}</span>
                    <i className="fal fa-arrow-right"></i>
                  </a>
                </li>
              </div>
            </div>
          </div>
        </div>
      </div>
      <CategoryDropDownMobile categoryData={categoryData}>
        {TT(LabelKeys.HEADER_PRODUCTS, locale)}
      </CategoryDropDownMobile>
    </div>
  );
};

export default Header;
