import _get from "lodash.get";
import React, { useContext, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import AttrSelectorWrapper from "../components/attr-selector-wrapper";
import { CartContext } from "../context/cartContext";
import Hooks from "../hooks";
import OrderUtil from "../utils/order-utils";
import { GenericUtil } from "../utils";
import { LabelKeys } from "../config/locale/label-config";
import { TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";
const ProductDetails = () => {
  const { locale } = useLocaleContext();
  const { id } = useParams();
  const history = useHistory();
  const [price, setPrice] = useState();
  const [isLoadingPrice, setIsLoadingPrice] = useState(false);
  const [selectionData, setSelectionData] = useState({
    resolvedAttributes: {},
    userSelection: {},
    selectedAttributes: {},
    finalSelection: {},
    configurationURL: "",
  });
  const [globalCart, setGlobalCart] = useContext(CartContext);
  const { storefrontId, productDetails, isLoading } = Hooks.useProductDetails(
    id,
    locale
  );
  document.title = TT(_get(productDetails, "name", ""), locale);
  useEffect(() => {
    localStorage.setItem("storefrontId", storefrontId);
  }, [storefrontId]);

  const onSelectionChange = (onChangeResponse) => {
    const {
      resolvedAttributes,
      userSelection,
      selectedAttributes,
      finalSelection,
      configurationURL,
      validationErrors,
      state,
    } = onChangeResponse;
    setSelectionData(onChangeResponse);
    if (state === "unresolvable")
      alert(
        validationErrors[0] + ", " + "Please choose some other configuration."
      );
    if (resolvedAttributes) fetchPriceInfo(resolvedAttributes);
  };

  const fetchPriceInfo = (resolvedAttributes) => {
    const {
      sku,
      // version,
      fulfillerId,
    } = productDetails;
    if (!resolvedAttributes) return;

    let requestBody = {
      storefrontID: storefrontId,
      mcpSku: sku,
      // productVersion: version,
      fulfillerID: fulfillerId,
      variables: resolvedAttributes,
    };
    setIsLoadingPrice(true);
    fetch(
      OrderUtil.URI.PRICE_INFO(storefrontId),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        setIsLoadingPrice(false);
        return res.json();
      })
      .then((res) => {
        setPrice({
          currency: res.price.currencyCode,
          amt: res.price.centAmount,
          taxRate: res.taxPercentage,
          totalAmt: res.totalPrice.centAmount,
        });
        if (res.price.centAmount === 0) {
          alert(
            "Sorry, we could not get the price details now. Please choose some other configuration."
          );
        }
      })
      .catch((err) => {
        setIsLoadingPrice(false);
        console.log("Price Error", err);
      });
  };

  const postCartApi = () => {
    if (_get(globalCart, "lineItems.length") > 0) {
      addLineItem();
    } else {
      createCart();
    }
  };

  const createCart = async () => {
    const { userSelection, finalSelection, selectedAttributes } = selectionData;
    const priceValue = price;
    const { productId, sku, fulfillerId, name } = productDetails;
    const dateAfter10Days = new Date();
    dateAfter10Days.setDate(dateAfter10Days.getDate() + 10);

    let requestBody = {
      currency: priceValue.currency,
      customerEmail: "prasenjit.chowdhuryc@cimpress.com",
      // customerId: "d3a170ac-3e20-43d6-95f0-cdbbb251135f",
      lineItems: [
        {
          productId,
          priceMode: "ExternalPrice",
          externalTotalPrice: {
            price: {
              currencyCode: priceValue.currency,
              centAmount: priceValue.amt,
            },
            totalPrice: {
              currencyCode: priceValue.currency,
              centAmount: priceValue.totalAmt,
            },
          },
          custom: {
            type: {
              key: "lineItemCustomFields",
            },
            fields: {
              lineItemNumber: new Date().getTime().toString(),
              promisedArrivalDate: dateAfter10Days.toISOString(),
              userSelectionV2: JSON.stringify(userSelection),
              finalSelectionV2: JSON.stringify(finalSelection),
              mcpSku: sku,
              variableAttributesV2: JSON.stringify(selectedAttributes),
              mcpProductName: name,
              fulfillerId: fulfillerId,
            },
          },
          quantity: parseInt(_get(userSelection, "Quantity")),
        },
      ],
      shippingAddress: {
        country: "DE",
        externalId: "123",
      },
    };

    let cart = await fetch(
      OrderUtil.URI.PLACE_CART(storefrontId),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGlobalCart(res);
        return res;
      })
      .catch((err) => {
        //this.showSnackBar("Error while placing Cart", "danger");
        alert("Error while placing Cart");
      });
    if (cart) {
      invokeDesigner(cart);
    }
  };

  const addLineItem = async () => {
    const { userSelection, finalSelection, selectedAttributes } = selectionData;

    const priceValue = price;
    const { productId, sku, fulfillerId, name } = productDetails;
    const dateAfter10Days = new Date();
    dateAfter10Days.setDate(dateAfter10Days.getDate() + 10);

    let cartInfo = await fetch(
      OrderUtil.URI.UPDATE_CART(storefrontId, _get(globalCart, "id")),
      { method: "GET" }
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGlobalCart(res);
        return res;
      })
      .catch((err) => {
        console.error("Error while fetching Cart");
      });

    let requestBody = {
      version: _get(cartInfo, "version"),
      actions: [
        {
          action: "addLineItem",
          productId,
          priceMode: "ExternalPrice",
          externalTotalPrice: {
            price: {
              currencyCode: priceValue.currency,
              centAmount: priceValue.amt,
            },
            totalPrice: {
              currencyCode: priceValue.currency,
              centAmount: priceValue.totalAmt,
            },
          },
          custom: {
            type: {
              key: "lineItemCustomFields",
            },
            fields: {
              lineItemNumber: new Date().getTime().toString(),
              promisedArrivalDate: dateAfter10Days.toISOString(),
              userSelectionV2: JSON.stringify(userSelection),
              finalSelectionV2: JSON.stringify(finalSelection),
              mcpSku: sku,
              variableAttributesV2: JSON.stringify(selectedAttributes),
              mcpProductName: name,
              fulfillerId: fulfillerId,
            },
          },
          quantity: parseInt(_get(userSelection, "Quantity")),
        },
      ],
    };

    let cart = await fetch(
      OrderUtil.URI.UPDATE_CART(storefrontId, _get(cartInfo, "id")),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGlobalCart(res);
        return res;
      })
      .catch((err) => {
        //this.showSnackBar("Error while placing Cart", "danger");
        alert("Error while placing Cart");
      });
    if (cart) {
      invokeDesigner(cart);
    }
  };

  const invokeDesigner = (cart) => {
    // return false;
    const { userSelection, selectedAttributes } = selectionData;

    const { sku, name, referenceId } = productDetails;
    let requestBody = {
      productKey: referenceId,
      userSelection: userSelection,
      lineItemName: name,
      sku: sku,
      isOrder: false,
      cartOrOrderId: cart.id,
      cimpressDesignerConfig:
        '{"enable":true,"pageUrl":"/tradeprint/bag-printing/classic-tote-bags","facts":[{"key":"Color_Name___LOV","value":"Solid Black"}],"disableNormalUploads":true}',
      lineItemId: _get(
        cart,
        `lineItems[${_get(cart, "lineItems.length") - 1}].id`
      ),
      surfaceBleed: 0,
      variableAttributes: selectedAttributes,
      redirectUrl: `${window.location.protocol}//${window.location.hostname}${
        window.location.port ? ":" + window.location.port : ""
      }/review/${cart.id}`,
    };
    formPost(`/designer`, { data: JSON.stringify(requestBody) });
    // history.push(`/review/${cartId}`);
  };

  const formPost = (path, params, method = "post") => {
    // The rest of this code assumes you are not using a library.
    // It can be made less wordy if you use one.
    const form = document.createElement("form");
    form.method = method;
    form.action = path;

    for (const key in params) {
      if (params.hasOwnProperty(key)) {
        const hiddenField = document.createElement("input");
        hiddenField.type = "hidden";
        hiddenField.name = key;
        hiddenField.value = params[key];

        form.appendChild(hiddenField);
      }
    }

    document.body.appendChild(form);
    form.submit();
  };

  return (
    <div>
      <section className="single-product-detail mt-5">
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-5 col-12">
              <div className="single-product-image-gallery">
                <div className="tab-content">
                  {_get(productDetails, "assets", []).map((item, index) => {
                    return (
                      <div
                        key={"product-image-" + index}
                        id={"product-image-" + index}
                        className={index === 0 ? "tab-pane active" : "tab-pane"}
                      >
                        <div className="single-product-image">
                          <img
                            src={_get(item, "url")}
                            className="img-fluid"
                            alt="product"
                          />
                        </div>
                      </div>
                    );
                  })}
                </div>
                <ul
                  className="nav nav-pills nav-justified product-images-thumbnail"
                  role="tablist"
                >
                  {_get(productDetails, "assets", []).map((item, index) => {
                    return (
                      <li className="nav-item" key={"product-nav-" + index}>
                        <a
                          className="nav-link active"
                          data-toggle="pill"
                          href={"#product-image-" + index}
                        >
                          <div
                            className="single-product-thumbnail"
                            style={{ maxWidth: "100px" }}
                          >
                            <img
                              src={_get(item, "url")}
                              className="img-fluid"
                            />
                          </div>
                        </a>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>

            <div className="col-lg-4 col-md-4 col-12">
              <div className="single-product-description">
                <h1 className="single-product-title">
                  {GenericUtil.limitWord(
                    _get(productDetails, "shortDescription", ""),
                    35
                  )}
                </h1>
                <div className="single-product-content-tab">
                  <ul className="nav nav-tabs nav-justified product-content-nav border-bottom-0">
                    <li className="nav-item">
                      <a
                        className="nav-link active text-left"
                        data-toggle="tab"
                        href="#home"
                      >
                        Description
                      </a>
                    </li>
                  </ul>
                  <div className="tab-content product-content-tab">
                    <div id="home" className="tab-pane pl-0 pt-0 active">
                      <p className="mb-4">
                        {GenericUtil.limitWord(
                          _get(productDetails, "description", ""),
                          1000
                        )}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="single-product-meta">
                <AttrSelectorWrapper
                  productDetails={{
                    ...(productDetails || {}),
                    storefrontId: storefrontId,
                  }}
                  onChange={onSelectionChange}
                />
              </div>
            </div>

            <div className="col-lg-3 col-md-2 col-12 cart-summary-wrapper">
              <div className="p-3 cart-product-info">
                <h6>
                  {GenericUtil.limitWord(_get(productDetails, "name", ""), 20)}
                </h6>
                <ul>
                  {Object.keys(
                    _get(selectionData, "selectedAttributes", {})
                  ).map((selectionKey) => {
                    return (
                      <div
                        className="d-flex justify-content-between p-1"
                        key={selectionKey}
                      >
                        <b>{selectionKey} :</b>
                        {_get(
                          selectionData,
                          `selectedAttributes[${selectionKey}]`,
                          {}
                        )}
                      </div>
                    );
                  })}
                </ul>
              </div>
              <div className="p-3 cart-total-info">
                <h6>{TT(LabelKeys.CART_TOTAL_HEADING, locale)}</h6>
                <ul>
                  <li className="d-flex justify-content-between px-1">
                    <span>{TT(LabelKeys.CART_SUBTOTAL, locale)}</span>
                    <span>
                      <strong>
                        &euro; {price ? Number(price.amt) / 100 : 0}
                      </strong>
                    </span>
                  </li>
                  <li className="d-flex justify-content-between px-1">
                    <span>
                      {TT(LabelKeys.CART_TAX_RATE, locale)}(
                      {_get(price, "taxRate")}%)
                    </span>
                    <span>
                      &euro;{" "}
                      {price
                        ? (
                            (Number(price.amt) / 100) *
                            (Number(price.taxRate) / 100)
                          ).toFixed(2)
                        : 0}
                    </span>
                  </li>
                </ul>
                <div className="d-flex justify-content-between py-3 px-1 cart-total-box">
                  <span>{TT(LabelKeys.CART_TOTAL, locale)}</span>
                  <span>
                    <strong>
                      &euro; {price ? Number(price.totalAmt) / 100 : 0}
                    </strong>
                  </span>
                </div>
                <button
                  className="btn w-100 mt-2 mb-2 btn-primary-custom text-center"
                  onClick={postCartApi}
                  disabled={!price || price.centAmount === 0}
                >
                  {isLoadingPrice ? (
                    <i className="fas fa-circle-notch fa-spin"></i>
                  ) : (
                    "Add Design"
                  )}
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default ProductDetails;
