/* eslint-disable import/prefer-default-export */
import useCarousel from "./useCarousel";
import useFooter from "./useFooter";
import useHeader from "./useHeader";
import useHomePage from "./useHomePage";
import useProductDetails from "./useProductDetails";
import usePrivacyPolicy from "./usePrivacyPolicy";
import useTermsAndCondition from "./useTermsAndCondition";
import useTeaser from "./useTeaser";
import useSingleImageCard from "./useSingleImageCard";
import useHighlightedCarouselCard from "./useHighlightedCarouselCard";

export default {
  useCarousel,
  useFooter,
  useHeader,
  useHomePage,
  useProductDetails,
  usePrivacyPolicy,
  useTermsAndCondition,
  useTeaser,
  useSingleImageCard,
  useHighlightedCarouselCard,
};
