import React, { useCallback, useRef, useState } from "react";
import ProductMenu from "./product-menu";

const SubCategoryMenu = ({ subCategories }) => {
  //const [isShown, setIsShown] = useState(false);
  //const handleShowTrue = useCallback(() => setIsShown(true));
  //const handleShowFalse = useCallback(() => setIsShown(false));
  return subCategories
    ? subCategories.map((item, index) => {
        return (
          <li
            key={"sub-menu-" + item.name}
            //onMouseEnter={handleShowTrue}
            //onMouseLeave={handleShowFalse}
          >
            <a title={item.name}>{item.name}</a>
            <ul className={false ? "sub-sub-menu active" : "sub-sub-menu"}>
              {SubCategoryMenu({ subCategories: item.subCategory })}
              <ProductMenu products={item.products} />
            </ul>
          </li>
        );
      })
    : null;
};

export default SubCategoryMenu;
