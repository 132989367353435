import _get from "lodash.get";
import React from "react";
import { LabelKeys, TT } from "../config/locale";
import { useLocaleContext } from "../context/localeContext";
import Hooks from "../hooks";

const Privacy = () => {
  const { locale } = useLocaleContext();
  let { privacyPolicy } = Hooks.usePrivacyPolicy(locale);
  document.title = TT(LabelKeys.TITLE_CART, locale);

  return (
    <div className="container">
      <div className="page d-block">
        <div className="row mt-5 mb-5">
          <div className="col-12 col-md-12">
            <div className="page-title mb-5">
              <h5>{_get(privacyPolicy, "title")}</h5>
            </div>
            <div className="page-section-content">
              <section
                id="section1"
                dangerouslySetInnerHTML={{
                  __html: _get(privacyPolicy, "description"),
                }}
              ></section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Privacy;
