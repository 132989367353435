import _get from "lodash.get";
import React, { useContext, useEffect } from "react";
import { CartContext } from "../context/cartContext";
import { Link, useParams } from "react-router-dom";
import OrderUtil from "../utils/order-utils";
import { useLocaleContext } from "../context/localeContext";
import { LabelKeys, TT } from "../config/locale";

const ReviewCart = () => {
  const { locale } = useLocaleContext();
  const { cartId } = useParams();
  const [globalCart, setGlobalCart] = useContext(CartContext);
  document.title = TT(LabelKeys.TITLE_REVIEW_CART, locale);
  useEffect(() => {
    let fetchCart = async (cartId, setGlobalCart) => {
      if (cartId) {
        let storefrontId = localStorage.getItem("storefrontId");

        await fetch(OrderUtil.URI.UPDATE_CART(storefrontId, cartId), {
          method: "GET",
        })
          .then((res) => {
            if (!res.ok) {
              throw Error(res.statusText);
            }
            return res.json();
          })
          .then((res) => {
            setGlobalCart(res);
          })
          .catch((err) => {
            console.error("Error while fetching Cart");
          });
      }
    };
    fetchCart(cartId, setGlobalCart);
  }, [cartId, setGlobalCart]);

  const handleRemoveClick = async (lineItemId, quantity) => {
    let storefrontId = localStorage.getItem("storefrontId");
    let requestBody = {
      version: _get(globalCart, "version"),
      actions: [
        {
          action: "removeLineItem",
          lineItemId: lineItemId,
          quantity: quantity,
        },
      ],
    };
    await fetch(
      OrderUtil.URI.UPDATE_CART(storefrontId, cartId),
      OrderUtil.generateHeader(requestBody, "POST")
    )
      .then((res) => {
        if (!res.ok) {
          throw Error(res.statusText);
        }
        return res.json();
      })
      .then((res) => {
        setGlobalCart(res);
        return res;
      })
      .catch((err) => {
        //this.showSnackBar("Error while placing Cart", "danger");
        alert("Error while removing line item");
      });
  };

  return (
    <div className="mt-5 mb-5">
      <div className="container">
        <div className="page">
          <div className="page-title">
            <h5>{TT(LabelKeys.REVIEW_CART_HEADING, locale)}</h5>
            <span className="sub-title">
              <Link to="/">
                {TT(LabelKeys.REVIEW_CART_CONTINUE_SHOPPING, locale)}
              </Link>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="cart">
          <div className="cart-header">
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="cart-header-title">
                  <span>{TT(LabelKeys.REVIEW_CART_ARTICLE, locale)}</span>
                </div>
              </div>
              {/*
              <div className="col-12 col-md-2">
                <div className="cart-header-title d-none d-md-block">
                  <span>Reference</span>
                </div>
              </div>
              */}
              <div className="col-12 col-md-2 d-none d-md-block">
                <div className="cart-header-title">
                  <span>{TT(LabelKeys.REVIEW_CART_QUANTITY, locale)}</span>
                </div>
              </div>
              <div className="col-12 col-md-2 d-none d-md-block">
                <div className="cart-header-title">
                  <span>{TT(LabelKeys.REVIEW_CART_TOTAL, locale)}</span>
                </div>
              </div>
            </div>
          </div>
          <div className="cart-body">
            {_get(globalCart, "lineItems", []).map((lineItem) => {
              let attributes = JSON.parse(
                _get(lineItem, "custom.fields.variableAttributesV2", {})
              );
              return (
                <div className="cart-items">
                  <div className="row">
                    <div className="col-12 col-md-6">
                      <div className="cart-items-wrapper">
                        <div className="article-img">
                          <img
                            src="https://dummyimage.com/140x140/ededed/ededed"
                            alt="img"
                          />
                        </div>
                        <div className="article-detail">
                          <div className="article-name">
                            <span>
                              {_get(lineItem, "custom.fields.mcpProductName")}
                            </span>
                          </div>
                          <div className="article-btn-set">
                            <button
                              className="btn btn-muted"
                              onClick={() => {
                                handleRemoveClick(
                                  _get(lineItem, "id"),
                                  _get(lineItem, "quantity")
                                );
                              }}
                            >
                              {TT(LabelKeys.REVIEW_CART_REMOVE, locale)}
                            </button>
                          </div>
                          <div className="article-info">
                            {Object.keys(attributes).map((selectionKey) => {
                              return (
                                <p key={selectionKey}>
                                  {selectionKey} :
                                  {_get(attributes, `[${selectionKey}]`, {})}
                                  <br />
                                </p>
                              );
                            })}
                          </div>
                        </div>
                      </div>
                    </div>
                    {/*
                    <div className="col-12 col-md-2">
                      <span className="d-flex d-md-none font-weight-normal mt-2">
                        Reference
                      </span>
                      <input
                        type="text"
                        className="form-control article-reference"
                        placeholder="ET 3493 IK"
                      />
                    </div>
                    */}
                    <div className="col-12 col-md-2">
                      <div className="article-quantity">
                        <span className="d-flex d-md-none font-weight-normal mr-4">
                          {TT(LabelKeys.REVIEW_CART_QUANTITY, locale)}
                        </span>
                        <span>{_get(lineItem, "quantity", "--")}</span>
                      </div>
                    </div>
                    <div className="col-12 col-md-2">
                      <div className="article-total">
                        <span className="d-flex d-md-none font-weight-normal mr-5">
                          {TT(LabelKeys.REVIEW_CART_TOTAL, locale)}
                        </span>
                        <span>
                          &euro;{" "}
                          {Number(_get(lineItem, "totalPrice.centAmount", 0)) /
                            100}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
          <div className="row mt-3">
            <div className="col-12 col-md-6">
              {/*
              <div className="cart-note">
                <div className="form-group">
                  <label for="noteOrder" className="form-label">
                    Add a Note to your order
                  </label>
                  <textarea
                    name="noteOrder"
                    className="form-control"
                    id="noteOrder"
                    cols="30"
                    rows="5"
                  ></textarea>
                </div>
              </div>
              */}
            </div>

            <div className="col-12 col-md-6">
              <div className="cart-total">
                <div className="cart-total-title">
                  <h5>{TT(LabelKeys.REVIEW_CART_CART_TOTAL, locale)}</h5>
                </div>
                <div className="cart-total-item">
                  <span>{TT(LabelKeys.REVIEW_CART_SUBTOTAL, locale)}</span>
                  <span className="font-weight-bold">
                    &euro;{" "}
                    {Number(
                      _get(globalCart, "taxedPrice.totalNet.centAmount", 0)
                    ) / 100}
                  </span>
                </div>
                <div className="cart-total-item">
                  <span>{TT(LabelKeys.REVIEW_CART_TAX, locale)}</span>
                  <span>
                    &euro;{" "}
                    {Number(
                      _get(globalCart, "taxedPrice.taxPortions", []).reduce(
                        (acc, cur) => {
                          return acc + cur.amount.centAmount || 0;
                        },
                        0
                      )
                    ) / 100}
                  </span>
                </div>
                <div className="cart-total-main">
                  <span className="title">
                    {TT(LabelKeys.REVIEW_CART_TOTAL, locale)}
                  </span>
                  <span>
                    &euro;{" "}
                    {Number(
                      _get(globalCart, "taxedPrice.totalGross.centAmount", 0)
                    ) / 100}
                  </span>
                </div>
                <div className="btn-group">
                  <Link
                    to={`/cart/${cartId}`}
                    className={`btn btn-primary-custom d-md-block ${
                      _get(globalCart, "lineItems.length") > 0
                        ? ""
                        : "link-disabled"
                    }`}
                  >
                    {TT(LabelKeys.REVIEW_CART_CHECKOUT, locale)}
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReviewCart;
